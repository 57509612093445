import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';

const today = new Date();

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'tr',
    languages: ['tr', 'en'],
    resources: {
      en: {
        translation: {
          "COPYRIGHT": `© ${today.getFullYear()} Global IT, all rights reserved.`,
          "tr-full": "Turkish",
          "en-full": "English",
          "WELCOME": "WELCOME!",
          "JUST_A_FEW_SECONDS": "Just a few seconds left",
          "LOGIN": "Login",
          "SMS_LOGIN_INFO_MSG": "Please enter your phone number to <2></2> log in to your <1>{{weoll}}</1> account.",
          "SMS_VERIFICATION_INFO_MSG": "Please enter the code sent to your phone<1></1> number in the field below.",
          "CONTINUE": "Continue",
          "GOOGLE_SIGN_IN": "Sign in with Google",
          "MICROSOFT_SIGN_IN": "Sign in with Microsoft",
          "PHONE_SIGN_IN": "Sign in with Phone Number",
          "BACK": "Back",
          "RESEND_CODE": "Resend Code",
          "SIGN_IN_INFO_MSG": "Please sign in to your <1>{{weoll}}</1> account.",
          "ENTER_YOUR_PHONE_NUMBER": "Enter Your Phone Number",
          "ENTER_SMS_CODE": "Enter SMS Code",
          "REQUIRED_FIELD": "Required",
          "INVALID_PHONE_NUMBER": "Invalid Phone Number",
          "INVALID_PHONE_NUMBER_ERROR": "You don't have an account with this number.",
          "LESS_13": "10 character limitation",
          "ADMIN_RESTRICTED_OPERATION": "Your account does not exist.",
          "ADMIN_RESTRICTED_OPERATION_PHONE": "Your account does not exist or the phone number is not in the system.",
          "USER_DISABLED": "Your account has been deactivated. Please contact your manager.",
          "INVALID_CREDENTIAL": "Try again! Be sure you entered the information correctly.",
          "UNKNOWN_ERROR": "Failed to login. Please contact your manager.",
          "TOO_MANY_REQUESTS": "Your access was temporarily blocked due to too many login attempts.",
          "INVALID_VERIFICATION_CODE": "Try again! You entered the verification code incorrectly.",
          "RESEND_SUCCESSFULLY": "Your code has been sent to {{phoneNumber}}. Please check your inbox.",
          "ACCOUNT_CREATION_MSG": "Thank you for waiting!<br>We are creating your account.",
          "MISSING_CLIENT_IDENTIFIER": "Validation error. Please contact your manager.",
          "FIRST_NAME": "First Name",
          "LAST_NAME": "Last Name",
          "EMAIL": "Email",
          "COMPANY": "Company",
          "FIRST_NAME_PLACEHOLDER": "Type your first name",
          "LAST_NAME_PLACEHOLDER": "Type your last name",
          "EMAIL_PLACEHOLDER": "Type your email address",
          "COMPANY_PLACEHOLDER": "Type your company name",
          "REGISTRATION_ERROR_MSG": "Your information could not be verified.<br> Would you like to log in?",
          "REGISTRATION_TOKEN_EXPIRED_MSG": "Your authentication token may have expired. Please revisit the URL that redirected you to this page or contact us for assistance.",
          "SOMETHING_WENT_WRONG": "Something went wrong!"
        }
      },
      tr: {
        translation: {
          "COPYRIGHT": `© ${today.getFullYear()} Global IT, tüm hakları saklıdır.`,
          "tr-full": "Türkçe",
          "en-full": "İngilizce",
          "WELCOME": "HOŞ GELDİN!",
          "JUST_A_FEW_SECONDS": "Sadece birkaç saniye kaldı",
          "LOGIN": "Giriş Yap",
          "SMS_LOGIN_INFO_MSG": "<1>{{weoll}}</1> hesabınıza giriş yapmak için lütfen <2></2> telefon numaranızı girin.",
          "SMS_VERIFICATION_INFO_MSG": "Lütfen telefon numaranıza gönderilen <1></1> kodu aşağıdaki alana girin.",
          "CONTINUE": "Devam Et",
          "GOOGLE_SIGN_IN": "Google ile Giriş Yap",
          "MICROSOFT_SIGN_IN": "Microsoft ile Giriş Yap",
          "PHONE_SIGN_IN": "Telefon Numarası ile Giriş Yap",
          "BACK": "Geri",
          "RESEND_CODE": "Kodu Tekrar Gönder",
          "SIGN_IN_INFO_MSG": "Lütfen <1>{{weoll}}</1> hesabınızla oturum açın.",
          "ENTER_YOUR_PHONE_NUMBER": "Telefon Numaranı Gir",
          "ENTER_SMS_CODE": "SMS Kodunu Gir",
          "REQUIRED_FIELD": "Zorunlu Alan",
          "INVALID_PHONE_NUMBER": "Geçersiz Telefon Numarası",
          "INVALID_PHONE_NUMBER_ERROR": "Bu numaraya ait bir hesap bulamadık.",
          "LESS_13": "10 karakter sınırı",
          "ADMIN_RESTRICTED_OPERATION": "Hesabınız mevcut değil.",
          "ADMIN_RESTRICTED_OPERATION_PHONE": "Hesabınız mevcut değil ya da telefon numaranız sistemde bulunmuyor.",
          "USER_DISABLED": "Hesabın devre dışı bırakıldı. Lütfen yöneticine başvur.",
          "INVALID_CREDENTIAL": "Tekrar dene! Bilgileri doğru girdiğinden emin ol.",
          "UNKNOWN_ERROR": "Giriş yapılamadı. Lütfen yöneticine başvur.",
          "TOO_MANY_REQUESTS": "Çok fazla giriş denemesi yaptığınız için geçici bir süreliğine erişiminiz engellendi.",
          "INVALID_VERIFICATION_CODE": "Tekrar dene! Doğrulama kodunu hatalı girdin.",
          "RESEND_SUCCESSFULLY": "Kodun {{phoneNumber}} numarasına gönderildi. Lütfen mesaj kutunu kontrol et.",
          "ACCOUNT_CREATION_MSG": "Beklediğiniz için teşekkürler!<br><span>Weoll</span> hesabını oluşturuyoruz.",
          "MISSING_CLIENT_IDENTIFIER": "Doğrulama başarısız. Lütfen yöneticine başvur.",
          "FIRST_NAME": "Adınız",
          "LAST_NAME": "Soyadınız",
          "EMAIL": "Eposta",
          "COMPANY": "Firma",
          "FIRST_NAME_PLACEHOLDER": "Adınızı yazınız",
          "LAST_NAME_PLACEHOLDER": "Soyadınızı yazınız",
          "EMAIL_PLACEHOLDER": "Epostanızı yazınız",
          "COMPANY_PLACEHOLDER": "Firmanızın adını yazınız",
          "REGISTRATION_ERROR_MSG": "Bilgileriniz doğrulanamadı.<br> Giriş yapmak ister misiniz?",
          "REGISTRATION_TOKEN_EXPIRED_MSG": "Kimlik doğrulama token'ınızın süresi dolmuş olabilir. Lütfen bu sayfayı yeniden açmak için yönlendirilmiş olduğunuz URL'yi ziyaret edin veya bizimle iletişime geçin.",
          "SOMETHING_WENT_WRONG": "Bilinmeyen bir hata oluştu!"
        }
      }
    },
    fallbackLng: false,
    debug: false,
    // debug: (process.env.NODE_ENV === 'development'),
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

  });

export default i18n;
