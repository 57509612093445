import store from '../core/actions/setReduxStore';
import { setError } from '../core/slice';

function errorHandle(error, isPhone) {
	if (error === 'auth/too-many-requests') {
		store.dispatch(setError('TOO_MANY_REQUESTS'));
	} else if (error === 'auth/invalid-phone-number') {
		store.dispatch(setError('INVALID_PHONE_NUMBER_ERROR'));
	} else if (error === "auth/admin-restricted-operation") {
		if(!isPhone) {
			store.dispatch(setError('ADMIN_RESTRICTED_OPERATION'));
		} else {
			store.dispatch(setError('ADMIN_RESTRICTED_OPERATION_PHONE'))
		}
	} else if (error === "auth/user-disabled") {
		store.dispatch(setError('USER_DISABLED'));
	} else if (error === "auth/invalid-credential") {
		store.dispatch(setError('INVALID_CREDENTIAL'));
	} else if (error === "auth/invalid-verification-code") {
		store.dispatch(setError('INVALID_VERIFICATION_CODE'));
	} else if (error === "auth/missing-client-identifier") {
		store.dispatch(setError('MISSING_CLIENT_IDENTIFIER'))
	} else {
		store.dispatch(setError('UNKNOWN_ERROR'));
	}
}

export default errorHandle;
